import "./fillYourNamePage.css";
import AnimatedPage from "../AnimatedPage";
import BackToDatesButton from "../../components/backToDatesButton/backToDatesButton";
import { Link, useNavigate } from "react-router-dom";
import salesRollBackgroundImg from "../../img/salesRollBackground.png";
import { useEffect, useRef, useState } from "react";
import EnrollBottomBlueButton from "../../components/enrollBottomBlueButton/enrollBottomBlueButton";
import startRollinManIcon from "../../img/startRollinManIcon.png";
import startRollinPhoneIcon from "../../img/startRollinPhoneIcon.png";
import { Id } from "../../const";
export default function FillYourNamePage() {
  const Navigate = useNavigate();
  const NameRef = useRef(null);
  const HeadButtTextRef = useRef(null);
  const [backDocLink, setBackDocLink] = useState(false);
  const [startRollinLink, setStartRollinLink] = useState(false);
  const FioRef = useRef();
  const TelRef = useRef();
  const [formData, setFormData] = useState({
    fio: "",
    tel: "+7 ",
    type: localStorage.type,
    date: localStorage.chosenDay,
    time: localStorage.chosenTime,
  });
  useEffect(() => {
    if (TelRef.current.value.length == 17 && FioRef.current.value.length >= 2) {
      setStartRollinLink(true);
    }
  }, []);
  const handleChange = (event) => {
    let typeFiller;
    if (localStorage.doctorName) {
      typeFiller = localStorage.type + ", " + localStorage.doctorName;
    }
    if (localStorage.actionId) {
      typeFiller =
        localStorage.type + ", Номер акции: " + localStorage.actionId;
    }
    formData.type = typeFiller;
    formData.date = localStorage.chosenDay;
    formData.time = localStorage.chosenTime;
    switch (event.target.name) {
      case "tel":
        mask(event);
        setFormData({
          ...formData,
          tel: event.target.value,
        });
        if (
          TelRef.current.value.length == 17 &&
          FioRef.current.value.length >= 2
        ) {
          setStartRollinLink(true);
        } else {
          setStartRollinLink(false);
        }
        localStorage.setItem("chosenTel", TelRef.current.value);
        break;
      case "fio":
        setFormData({
          ...formData,
          fio: event.target.value,
        });
        if (
          TelRef.current.value.length == 17 &&
          FioRef.current.value.length >= 2
        ) {
          setStartRollinLink(true);
        } else {
          setStartRollinLink(false);
        }
        localStorage.setItem("chosenFio", FioRef.current.value);
        break;
    }
  };
  const urlParams = new URLSearchParams(window.location.search);
  const doctorId = Number(urlParams.get("id"));
  function mask(event) {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 3) event.preventDefault();
    let matrix = "+7 (___) ___ ____",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
    i = new_value.indexOf("_");
    if (i != -1) {
      i < 5 && (i = 3);
      new_value = new_value.slice(0, i);
    }
    let reg = matrix
      .substr(0, event.target.value.length)
      .replace(/_+/g, function (a) {
        return "\\d{1," + a.length + "}";
      })
      .replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (
      !reg.test(event.target.value) ||
      event.target.value.length < 5 ||
      (keyCode > 47 && keyCode < 58)
    )
      event.target.value = new_value;
    if (event.type == "blur" && event.target.value.length < 5)
      event.target.value = "";
  }
  const makeRollin = () => {
    console.log(startRollinLink);
    let form = new FormData();
    form.append("name", formData.fio);
    form.append("phone", formData.tel);
    form.append("type", formData.type);
    form.append("date", formData.date);
    form.append("time", formData.time);
    fetch("https://cbt32.store/bot.php", {
      method: "POST",
      body: form,
    }).then((response) => {
      let urlParams = new URLSearchParams(window.location.search);
      let key = urlParams.get("key");
      form.append("ID", Id);
      fetch("https://cbt32.store/addApp.php", {
        method: "POST",
        body: form,
      });
      if (key == "actions") {
        Navigate("/rollinFinal?id=" + doctorId + '&type="actions"');
      } else {
        Navigate("/rollinFinal?id=" + doctorId + '&type="doc"');
      }
    });
  };
  return (
    <AnimatedPage>
      <div className="fill_your_name_page_container">
        <BackToDatesButton />
        <img className="man_woman_searching" src={salesRollBackgroundImg} />
        <div className="fill_your_name_page_inputs">
          <p className="fill_info_about_patient">Заполните данные о пациенте</p>
          <div className="fio_phone_input_box">
            <div className="icons_box_fill_your_name">
              <img className="startRollinManIcon" src={startRollinManIcon} />
            </div>
            <input
              className="fio_phone_inputs_fill_your_name"
              type="text"
              name="fio"
              onChange={handleChange}
              ref={FioRef}
              placeholder="Ваши ФИО"
            ></input>
          </div>
          <div className="fio_phone_input_box">
            <div className="icons_box_fill_your_name">
              <img
                className="startRollinPhoneIcon"
                src={startRollinPhoneIcon}
              />
            </div>
            <input
              className="fio_phone_inputs_fill_your_name"
              type="text"
              name="tel"
              onChange={handleChange}
              ref={TelRef}
              placeholder="Телефон"
            ></input>
          </div>
          <div
            onClick={startRollinLink ? makeRollin : ""}
            className={startRollinLink ? "" : "open_button"}
          >
            <EnrollBottomBlueButton />
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
